import piazzaLogoImg from "../../assets/logos/piazza-gray.svg";
import piazzaLogoImgSegundo from "../../assets/logos/piazza-dourado.svg";
import partnersLogoImg from "../../assets/logos/partners-gray.svg";
import partnersLogoImgSegundo from "../../assets/logos/partners-dourado.svg";
import quadraLogoImg from "../../assets/logos/quadra-gray.svg";
import quadraLogoImgSegundo from "../../assets/logos/quadra-dourado.svg";
import { Container, SupItems } from "./styles";
import ScrollAnimation from "react-animate-on-scroll";

export function Companies() {
  return (
    <Container>
      <ScrollAnimation animateIn="animate__fadeInUp">
        <SupItems>
          <div className="imagem-hover">
            <span>Realização</span>
            <div className="imagemHover-on text-center">
              <img src={piazzaLogoImg} alt="Piazza Incorporadora" />
            </div>
            <div className="imagemHover-off text-center">
              <img src={piazzaLogoImgSegundo} alt="Piazza Incorporadora" />
            </div>
          </div>
          <div className="imagem-hover">
            <span>Comercialização</span>
            <div className="imagemHover-on text-center">
              <img src={partnersLogoImg} alt="Partners Empreendimentos" />
            </div>
            <div className="imagemHover-off text-center">
              <img
                src={partnersLogoImgSegundo}
                alt="Partners Empreendimentos"
              />
            </div>
          </div>
          <div className="imagem-hover">
            <span>Construção e administração</span>
            <div className="imagemHover-on text-center">
              <img src={quadraLogoImg} alt="Quadra Engenharia" />
            </div>
            <div className="imagemHover-off text-center">
              <img src={quadraLogoImgSegundo} alt="Quadra Engenharia" />
            </div>
          </div>
        </SupItems>
      </ScrollAnimation>
      <p className="copyright text-dark">
        Todas as imagens e plantas são meramente ilustrativas com sugestão de
        decoração. Os móveis, assim como alguns materiais de acabamento
        representados nas plantas, não fazem parte do contrato de vendas.
        Projeto em aprovação e sujeito a alterações de acordo com o memorial
        descritivo de empreendimento.
      </p>
    </Container>
  );
}
