import styled from "styled-components";

import varandaPng from "../../assets/varanda.png";

export const Container = styled.div`
  width: 100%;
  background-image: url(${varandaPng});
  background-repeat: no-repeat;
  background-size: cover;
`;
export const ContainerSwiper = styled.div`
  padding: 8rem 1rem;
`;
