import { SectionComodidades } from "../SectionComodidades";
import { SectionCoworking } from "../SectionCoworking";
import { SectionFachada } from "../SectionFachada";
import { SectionOndeMoraSeuSonho } from "../SectionOndeMoraSeuSonho";
import { SectionConforto } from "../SectionConforto";
import { SectionVaranda } from "./../SectionVaranda";
import { SectionGaleria } from "./../SectionGaleria";
import { SectionFormulario } from "../SectionFormulario";

export function Main() {
  return (
    <main>
      <SectionOndeMoraSeuSonho />
      <SectionVaranda />
      <SectionConforto />
      <SectionCoworking />
      <SectionComodidades />
      <SectionFachada />
      <SectionGaleria />
      <SectionFormulario />
    </main>
  );
}
