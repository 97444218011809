import gradeSvg from "../../assets/elements/grade.svg";
import { GaleriaInterativa } from "../GaleriaInterativa";
import { Container } from "./styles";
import ElementTwoLine from "./../ElementTwoLine/index";

export function SectionGaleria() {
  return (
    <Container>
      <div className="header">
        {/* <img src={gradeSvg} alt="grade vertical" className="grade-vertical" /> */}

        <span className="mx-auto border">
          <h2 className="text-gold text-thin text-center">Galeria</h2>
        </span>
      </div>
      <GaleriaInterativa />
    </Container>
  );
}
