import styled from "styled-components";

export const Container = styled.div`
  padding: 8rem 1rem 14rem;
  background-color: transparent;
  .grade-vertical {
    position: absolute;
    left: 0;
    width: 6rem;
    @media (max-width: 767.98px) {
      width: 2rem;
    }
  }
  .header {
    div {
      max-width: 100%;
    }
    h2 {
      text-transform: uppercase;
    }
  }
`;
