import exampleSignatureImg from "../../assets/testimonials/signature.png";
import severino from "../../assets/testimonials/example-1.png";
import joao from "../../assets/testimonials/joao.png";
import benedito from "../../assets/testimonials/example-2.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.scss";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

export default function SwipperTestimonials() {
  return (
    <>
      <Swiper navigation={true} loop={true} spaceBetween={16} className="SwipperTestimonials">
        <SwiperSlide>
          <div className="SwiperContent p-5">
            <img src={severino} alt="" className="img-photo" />
            <div>
              <h3>Severino Marcos</h3>
              <p>
                "Representa um novo conceito de concepção livre, sem amarras, no contexto da paisagem urbana de Belém. Arquitetura em perfeita comunhão com a bela praça Batista Campos."
              </p>
              <p>
                <span>Projeto Arquitetônico</span>
              </p>
              <div className="text-center">
                {/* <img
                  src={exampleSignatureImg}
                  alt=""
                  className="img-signature"
                /> */}
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="SwiperContent">
            <img src={joao} alt="" className="img-photo" />
            <div>
              <h3>João Armentano</h3>
              <p>
                "As formas contemporâneas da arquitetura, contrastam com as linhas clássicas da Praça, e o paisagismo procurou integrá-las com um desenho natural, trazendo como grande atrativo a bela vista da copa das mangueiras existentes através da borda infinita na piscina."
              </p>
              <p>
                <span>Projeto de interiores das áreas comuns</span>
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="SwiperContent">
            <img src={benedito} alt="" className="img-photo" />
            <div>
              <h3>Benedito Abbud</h3>
              <p>
                "A implantação desse empreendimento, já de cara, dá de presente um visual único, que emoldura o projeto, e valoriza o morar. No seu lar, curtindo a família, dividindo os bons momentos com os amigos, relaxando ou simplesmente vivendo todo dia em cada espaço que foi criado com as melhores soluções!"
              </p>
              <p>
                <span>Projeto de paisagismo</span>
              </p>
              {/* <img src={exampleSignatureImg} alt="" className="img-signature" /> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
