import { Container, FeatureContent } from "./styles";

import ScrollAnimation from "react-animate-on-scroll";
import ApartamentSvg from "../../assets/apartament.png";
import BedSvg from "../../assets/bed.png";
import imgVideo from "../../assets/img-video.webp";
import videoModal from "../../assets/videos/video.mp4";
import { Comodidade } from "../Comodidade";
import ElementTwoLine from "../ElementTwoLine";
import { PlayVideo } from "../PlayVideo";

export function SectionConforto() {
  return (
    <Container>
      {/* <img src={gradeSvg} alt="grade vertical" className="grade-vertical" /> */}

      <h2 className="text-gold text-thin text-center">
        DESCUBRA O PRAZER EM VIVER BEM
      </h2>
      <ElementTwoLine />
      <p className="text-gray text-center text-thin">
        Ambientes integrados traduzem uma <br /> forma elegante de viver a vida.
      </p>
      <div className="row row-cols-md-3 justify-content-center">
        <FeatureContent>
          <ScrollAnimation animateIn="animate__fadeInLeft">
            <Comodidade
              comodidadeImg={ApartamentSvg}
              textSup={"APARTAMENTOS"}
              textSub={"de 234,72m² até 407,15m²"}
            />
          </ScrollAnimation>
        </FeatureContent>

        <FeatureContent isMid={true}>
          <ScrollAnimation animateIn="animate__fadeInDown">
            <Comodidade
              comodidadeImg={BedSvg}
              textSup={"3 SUÍTES"}
              textSub={""}
            />
          </ScrollAnimation>
        </FeatureContent>
      </div>

      <h2 className="text-gold text-thin text-center text-uppercase">
        Assista ao vídeo e saiba mais sobre o empreendimento
      </h2>

      <ElementTwoLine />

      <PlayVideo
        imagemCapa={imgVideo}
        video={videoModal}
        idModal="videoConcept"
        textoAlt="Vídeo Quadra Concept"
      />
    </Container>
  );
}
