import styled from "styled-components";

export const Container = styled.section`
  margin-top: 6rem;
  margin-bottom: 2rem;
  position: relative;

  .lobby {
    position: absolute;
    max-width: 50rem;
    right: 0;

    z-index: -1;
  }
  @media (min-width: 1566px) {
    .lobby {
      // max-width: 46rem;
    }
  }
`;

export const Content = styled.div`
  padding: 0 4rem;
  margin: 0 auto;
  width: 100%;
  max-width: 70rem;

  .description-content {
    max-width: 24rem;
    h2 {
      font-family: "Gilroy Light" !important;
      color: var(--gold-color);
      font-size: 2.2rem;
    }
    p {
      color: var(--gold-color);
      font-size: 1rem;
      @media (max-width: 575.98px) {
        background-color: ;
        background-color: rgb(203, 143, 85, 0.9);
        padding: 1rem;
        color: var(--shape-color);
      }
    }
  }
  .footer-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    align-items: center;
  }

  @media (min-width: 1566px) {
    .footer-content {
      align-items: end;
    }
  }

  @media (max-width: 575.98px) {
    .footer-content {
      margin-top: 8rem;
      justify-content: center;
    }
  }
`;
