import ScrollAnimation from "react-animate-on-scroll";
import gradeSvg from "../../assets/elements/grade.svg";
import fachadaImg from "../../assets/fachada.png";
import localizationImg from "../../assets/localization.png";
import { DescriptionBlock } from "../DescriptionBlock";
import ElementTwoLine from "../ElementTwoLine";
import { Container, Content } from "./styles";

export function SectionOndeMoraSeuSonho() {
  return (
    <Container>
      <img src={fachadaImg} alt="Fachada Piazza" className="lobby" />
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="description-content">
              <ScrollAnimation animateIn="animate__fadeIn">
                <h2 className="text-gold text-thin bg-mobile">
                  ONDE MORA O <br /> SEU SONHO?
                </h2>
                <ElementTwoLine />
              </ScrollAnimation>
              <p className="text-gray mt-3 bg-mobile">
                Cercado de muito verde e de todas as conveniências que o bairro tem a oferecer, o Piazza by Quadra é um convite para agradáveis passeios ao ar livre.
              </p>
              <p className="text-gray mt-3 bg-mobile">
                Um projeto que harmoniza e inspira, com assinaturas de arquitetos renomados como Benedito Abbud, João Armentano e Severino Marcos. A essência da sofisticação está em cada detalhe. Ambientes integrados traduzem uma forma elegante de viver a vida.
              </p>
              <p className="text-gray mt-3 bg-mobile">
                Descubra o prazer de viver bem no Piazza by Quadra.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container mt-5">
        <h2 className="text-gold text-thin">LOCALIZAÇÃO VALORIZADA</h2>
        <ElementTwoLine />
        <p className="text-gray">O Verde Invade</p>
      </div> */}
    </Container>
  );
}
