import styled from "styled-components";

export default function ElementTwoLine() {
  return (
    <Element>
      <span></span>
      <span></span>
    </Element>
  );
}

const Element = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 3rem;
  gap: 0.2rem;
  flex-direction: column;
  span {
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: var(--gold-color);
  }
`;
