import styled from "styled-components";

export const Wrapper = styled.div`
  height: 120px;
  max-width: 17rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;
  align-items: center;
  gap: 1.5rem;
  width: -webkit-fill-available;

  @media (max-width: 1200.98px) {
    justify-self: center;
  }
  div {
    h3 {
      font-family: "Gilroy Extra";
      font-size: 1rem;
      color: #848484;
    }
    p {
      font-family: "Gilroy Extra";
      font-size: 0.8rem;
      color: #848484;
    }
  }
`;
