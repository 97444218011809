import styled from "styled-components";
import varandaImg from "../../assets/varanda.png";

export const Container = styled.section`
  background-image: url(${varandaImg});
  background-color: var(--black-color);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  // margin-bottom: 10rem;

  img {
    width: calc(100% - 8rem);
    align-self: flex-end;
    @media (max-width: 767.98px) {
      align-self: center;
    }
    margin-top: 20rem;
    @media (max-width: 575.98px) {
      margin-top: 23rem;
    }
    @media (max-width: 1399.98px) {
      margin-top: 8rem;
      max-width: 24rem;
    }
    margin-right: 4rem;
    border: 3px solid var(--gold-color);

    max-width: 30rem;
    min-width: 20rem;
    height: auto;
    z-index: 1;
    @media (max-width: 575.98px) {
      top: 24rem;
    }
  }
`;
