import { Comodidade } from "../Comodidade";
import { MainContainer } from "../MainContainer";
import { ComodidadeContainer, Container } from "./styles";
import { comodidades } from "./comodidadesData";
import Delayed from "../helpers/Delayed";
import ElementTwoLine from "../ElementTwoLine";
import imgVideo from "../../assets/capa-video-comudidades.jpg";
import videoModal from "../../assets/videos/video2.mp4";
import { PlayVideo } from "../PlayVideo";
import { SwiperComodidade } from "../SwiperComodidade";

export function SectionComodidades() {
  return (
    <MainContainer>
      <Container>
        <h2 className="text-gold text-thin">Comodidades</h2>
        <ElementTwoLine />

        <PlayVideo
          imagemCapa={imgVideo}
          video={videoModal}
          idModal="videoComodidades"
          textoAlt="Vídeo Quadra Piazza"
        />

        <ComodidadeContainer>
          <SwiperComodidade />
        </ComodidadeContainer>
      </Container>
    </MainContainer>
  );
}
