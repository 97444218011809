import ScrollAnimation from "react-animate-on-scroll";
import mapaPng from "../../assets/map.png";
import { Container } from "./styles";

export function SectionVaranda() {
  return (
    <ScrollAnimation animateIn="animate__fadeIn">
      <Container>
        <div className="container mt-4 pt-4">
          <h4 className="text-light text-thin">VARANDA COM VISTA PARA PRAÇA</h4>
        </div>

        {/* <img src={mapaPng} alt="Localização" /> */}
      </Container>
    </ScrollAnimation>
  );
}
