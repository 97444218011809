import { DescriptionBlock } from "../DescriptionBlock";
import { Container } from "./styles";

export function SectionCoworking() {
  return (
    <Container>
      <div>
        <DescriptionBlock text="Você tem muitos motivos para viver bem. E agora tem o lugar." />
      </div> 
    </Container>
  );
}
