import { useEffect, useState } from "react";
import { Button, Container, ContainerGaleria, Tab } from "./styles";
import { SwipperGaleria } from "../SwiperGaleria";
import { api } from "../../services/api";
import axios from "axios";

const localApi = axios.create({
  baseURL: "https://quadra.com.vc/api/",
});

export function GaleriaInterativa() {
  const [areaComumButton, setAreaComumButton] = useState(false);
  const [apartamentosButton, setApartamentosButton] = useState(true);
  const [plantasButton, setPlantasButton] = useState(false);
  const [slidesLoad, setSlidesLoad] = useState(true);

  const [apartaments, setApartaments] = useState([{}]);
  const [areasComuns, setAreasComuns] = useState([{}]);
  const [plantas, setPlantas] = useState([{}]);
  const [showAreasComuns, setShowAreasComuns] = useState(false);
  const [showApartaments, setShowApartaments] = useState(false);
  const [showPlantas, setShowPlantas] = useState(false);
  async function axiosFunction() {
    // const response = await api.get("empreendimentos/1/pictures"); // Lux Design Concept
    // const response = await api.get("empreendimentos/4/pictures"); // Lux Design Living
    const response = await localApi.get("empreendimentos/5/pictures"); // Piazza by Quadra
    setApartaments(response.data["Apartamentos"]);
    // setFachadas(response.data.fachadas);
    setPlantas(response.data["Plantas"]);
    setAreasComuns(response.data["Áreas Comuns"]);
    setShowApartaments(true);
    setSlidesLoad(false);
  }

  useEffect(() => {
    axiosFunction();
  }, []);

  function handleResets() {
    setAreaComumButton(false);
    setApartamentosButton(false);
    // setFachadasButton(false);
    setPlantasButton(false);
    setShowAreasComuns(false);
    setShowApartaments(false);
    setShowPlantas(false);
  }

  function handleChangeSwiper(props) {
    handleResets();
    if (props === areasComuns) {
      setAreaComumButton(true);
      setShowAreasComuns(true);
    }
    if (props === apartaments) {
      setApartamentosButton(true);
      setShowApartaments(true);
    }
    // if (props === fachadas) {
    //   setFachadasButton(true);
    // }
    if (props === plantas) {
      setPlantasButton(true);
      setShowPlantas(true);
    }
  }

  return (
    <Container>
      <Tab>
        <Button
          type="button"
          onClick={() => handleChangeSwiper(apartaments)}
          isActive={apartamentosButton}
        >
          Apartamentos
        </Button>
        <Button
          type="button"
          onClick={() => handleChangeSwiper(areasComuns)}
          isActive={areaComumButton}
        >
          Áreas Comuns
        </Button>
        {/* <Button
          type="button"
          onClick={() => handleChangeSwiper(fachadas)}
          isActive={fachadasButton}
        >
          Fachadas
        </Button> */}
        <Button
          type="button"
          onClick={() => handleChangeSwiper(plantas)}
          isActive={plantasButton}
        >
          Plantas
        </Button>
      </Tab>
      <ContainerGaleria>
        {showApartaments && <SwipperGaleria slidesData={apartaments} />}
        {showAreasComuns && <SwipperGaleria slidesData={areasComuns} />}
        {showPlantas && <SwipperGaleria slidesData={plantas} />}
      </ContainerGaleria>
    </Container>
  );
}
