import styled from "styled-components";

export const Container = styled.section`
  padding-top: 4rem;
  padding-bottom: 4rem;
  @media (max-width: 575.98px) {
    padding-top: 7rem;
  }

  .video-modal {
    position: relative;
    
    a{
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
`;

export const ComodidadeContainer = styled.div`
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: center;
  align-items: center;
  gap: 4rem;
`;
