import espacoGourmet from "../../assets/comodidades/espaco-gourmet.svg";
import espacoKids from "../../assets/comodidades/espaco-kids.svg";
import patryRoom from "../../assets/comodidades/party-room.svg";
import petPlace from "../../assets/comodidades/pet-place.svg";
import sacadaGoumert from "../../assets/comodidades/sacada-goumert.svg";
import sauna from "../../assets/comodidades/sauna.svg";
import swimmingPool from "../../assets/comodidades/swimming-pool.svg";

export const comodidades = [
  {
    id: 1,
    comodidadeImg: sacadaGoumert,
    textSup: "Hall Social dos Apartamentos ",
    textSub: "com Piso em Granito",
  },
  {
    id: 2,
    comodidadeImg: espacoGourmet,
    textSup: "Guarda Corpo dos apartamentos ",
    textSub: "em vidro autoportante",
  },
  {
    id: 3,
    comodidadeImg: petPlace,
    textSup: "Pet Place",
    textSub: "",
  },
  {
    id: 4,
    comodidadeImg: patryRoom,
    textSup: "Bar molhado",
    textSub: "com hidromassagem",
  },
  {
    id: 5,
    comodidadeImg: swimmingPool,
    textSup: "Piscina de borda infinita",
    textSub: "",
  },
  {
    id: 6,
    comodidadeImg: sauna,
    textSup: "Sauna",
    textSub: "",
  },
  /*  {
    id: 7,
    comodidadeImg: sauna,
    textSup: "Sauna",
    textSub: "com vista panorâmica",
  }, */
  {
    id: 7,
    comodidadeImg: espacoKids,
    textSup: "Espaço Kids",
    textSub: "",
  },
  /* {
    id: Math.random(),
    comodidadeImg: usb,
    textSup: "Tomadas USB",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: wheelchair,
    textSup: "Elevador PCD",
    textSub: "Para acesse à piscina",
  },
  {
    id: Math.random(),
    comodidadeImg: mountainBike,
    textSup: "Bicicletário",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: petPlace,
    textSup: "Pet Place",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: salaJogos,
    textSup: "Salão de Jogos ",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoKids,
    textSup: "Espaço Kids",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: storage,
    textSup: "Storage",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: patryRoom,
    textSup: "Salão de Festas",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: sacadaGoumert,
    textSup: "Sacada Gourmet",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: spa,
    textSup: "Spa",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: espacoGourmet,
    textSup: "Espaço Gourmet",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: conesPlayground,
    textSup: "Playground",
    textSub: "",
  },
  {
    id: Math.random(),
    comodidadeImg: circuitoSeguranca,
    textSup: "Circuito de Segurança",
    textSub: "",
  }, */
];
