import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1200px;
`;

interface ButtonProps {
  isActive?: boolean;
}

export const Tab = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`;

export const Button = styled.button<ButtonProps>`
  border: none;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;

  background-color: ${(props) =>
    props.isActive ? "rgba(213, 180, 128, 0.38)" : "transparent"};
  color: #7b573f;
  font-weight: ${(props) => (props.isActive ? "600" : "400")};
`;

export const ContainerGaleria = styled.div`
  height: 80vh;
`;
