// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.scss";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";
import ScrollAnimation from "react-animate-on-scroll";

// install Swiper modules
SwiperCore.use([Navigation]);

export function SwipperGaleria({ slidesData }) {
  return (
    <>
      <ScrollAnimation animateIn="animate__fadeIn">
        <Swiper navigation={true} className="SwipperGaleria">
          {slidesData.map((slide, index) => (
            <SwiperSlide key={index}>
              <p
                data-fancybox="single"
                data-src={`https://quadra.com.vc/img-render/plan-pictures/g/${slide.picture}`}
              >

                <div style={{ borderRadius: "8px", backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundImage: `url(https://quadra.com.vc/img-render/plan-pictures/g/${slide.picture})` }}>
                  <div style={{margin: "0px 768px 0px 0px", width: "100%", minHeight: "500px"}}></div>
                </div>

                {/* <img
                  src={`https://quadra.com.vc/img-render/plan-pictures/g/${slide.picture}`}
                  alt={slide.name}
                /> */}
              </p>
              <div className="footer mt-2">
                <h3>{slide.name}</h3>
                {/* <p>Apartamento de 234m², até 2 suítes, 3 vaga na garagem</p> */}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </ScrollAnimation>
    </>
  );
}
